import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Popup } from 'base/ui/popups';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faChevronRight, faClose, faHistory } from '@fortawesome/free-solid-svg-icons';
import { GenericException } from 'base/ui/errors';
import { ViewPort } from 'base/constants';
import { LoadingOverlay } from 'base/ui/status';
import { currencyToSymbol, defined } from 'base/utils/common';
import { broadcaster, useBroadcastedState } from 'base/utils/events';
import ResponsivePopup from '../../ui/ResponsivePopup';
import CartAndOrderView from '../store/CartAndOrderView';
import { useCurrentUser } from 'base/app';
import { cache } from '../../../user';
import { useNavigate } from 'react-router-dom';
import SelectDeliveryPoint from '../store/SelectDeliveryPoint';
import SelectDeliveryTime from '../store/SelectDeliveryTime';
import useCheckout from '../store/useCheckout';
import { getByIds } from 'base/get_by_ids';
import { canModifyOrder } from '../../../helpers/StoreHelper';
import UserOrders from '../store/UserOrders';
import { last } from 'base/utils';
import { INBOX_MESSAGE_TYPE_WA_INCOMING_MSG } from 'base/ui/chat';
import { OrgUserChatSupportButtons } from '../../../csm/user';

function SelectDeliveryRoute({delivery_routes, onSelect}) {
    return (
      <ul className='tw-list tw-divide-y'>
        {delivery_routes?.map((dinfo) => (
          <li key={dinfo._id} onClick={() => onSelect(dinfo)} className='tw-px-4 tw-py-3 hflex tw-text-sm'>
            {dinfo.title}
            <FontAwesomeIcon icon={faChevronRight} />
          </li>
        ))}
      </ul>
    )
}

function BfcCartTitle({title, onClose, onViewOrders}) {
    return (
        <div className="tw-w-full tw-h-12 hflex tw-pl-4 tw-text-gray-600 tw-border-b">
            <span className='tw-text-sm tw-font-bold '>{title}</span>
            <div className='tw-flex tw-items-center'>
                <button className='tw-bg-yellow-500 tw-px-3 tw-py-1 tw-text-xs tw-text-black tw-rounded' onClick={onViewOrders}>
                    <FontAwesomeIcon icon={faHistory} />&nbsp;
                    Orders
                </button>
                <FontAwesomeIcon icon={faClose} width={22} height={22} className='tw-p-4 tw-cursor-pointer tw-ml-auto' onClick={onClose} />
            </div>
        </div>
    )
}

function DeliveryRouteUserBottomChatOptions({user_id, session_data, onChatResolved, delivery_route_id: _delivery_route_id}) {
    const [delivery_route_id, setDeliveryRouteId] = useState(_delivery_route_id || session_data.active_delivery_route_id)
    const [delivery_routes, setDeliveryRoutes] = useState(session_data.delivery_routes);
    const [cart, setCart] = useBroadcastedState('cart_updated', session_data.cart || {});
    const [order, setOrder] = useBroadcastedState('order_updated', {});
    
    const [selected_delivery_point, setSelectedDeliveryPoint] = useState();
    const [selected_delivery_time_range, setSelectedDeliveryTimeRange] = useState();
    const [init_data, setInitData] = useState({});
    const [is_show_cart, setShowCart] = useState(false);
    const [is_show_orders, setShowOrders] = useState(false);
    
    const user = useCurrentUser();
    
    const [is_loading, setLoading] = useState(false);

    const ctx = useRef({}).current;
    const navigate = useNavigate();

    const {delivery_route, buying_for_user} = init_data;
    /* Checkout Hook */
    useCheckout({cart, delivery_route, buying_for_user});

    const fetchCart = () => {
        if(ctx.is_loading) return; // nothing to do
        ctx.is_loading = true;
        setLoading(true);

        axios.post(
            `/api/init/${delivery_route_id}`, {fields: []},
            {
                /* retrieve init data for that customer */
                "params": {
                    cart_id: session_data.cart?._id 
                        ? session_data.cart._id 
                        : delivery_route_id === session_data.active_delivery_route_id 
                            ? cache.get([delivery_route_id, user_id, "cart_id"]) 
                            : null,
                    buying_for_user_id: user_id,
                    delivery_point_name: selected_delivery_point?.name || cache.get([delivery_route_id, user_id, "dpoint_name"]),
                    delivery_time: selected_delivery_time_range?.[0]
                }
            }
        ).then(
            (resp) => {
                if(resp.data.errors){
                    Popup.show("Errors", <GenericException ex={resp.data.errors} />);
                    return;
                }
                if (resp.data.delivery_route) {
                    /* you are trying to access a different org from a custom domin, redirect to home */
                    if(
                        !window.location.hostname.endsWith("sukhiba.com")
                        && window?.delivery_org_id
                        && resp.data.delivery_route.delivery_org?._id !== window.delivery_org_id
                    ){
                        navigate("/landing", {replace: true});
                        return;
                    }
                    const init_data = {
                        delivery_route: resp.data.delivery_route,
                        buying_for_user: resp.data.buying_for_user
                    }
                    const _cart = resp.data.cart || {}
                    setCart(_cart);
                    const _order = canModifyOrder(resp.data.order) ? resp.data.order : {}
                    setOrder(_order);
                    init_data.delivery_route.is_user_manager = resp.data.is_user_manager;
                    init_data.delivery_route.currency_symbol = currencyToSymbol(init_data.delivery_route.currency)
                    init_data.delivery_route.delivery_time_ranges = resp.data.delivery_time_ranges;
                    setInitData(init_data);
                }
            }
        ).finally(
            () => {
                ctx.is_loading = false;
                setLoading(false);
            }
        );
    }

    useEffect(() => {
        if (cart?.delivery_point_name) {
            cache.set([delivery_route_id, user_id, "dpoint_name"], cart.delivery_point_name);
        }
    }, [delivery_route_id, user_id, cart?.delivery_point_name])

    useEffect(() => {
        if (is_show_cart && delivery_route_id) {
            fetchCart();
        }
    }, [delivery_route_id, selected_delivery_point, selected_delivery_time_range, is_show_cart])

    const showDeliveryPointsSelectionPopup = () => {
        var popup = Popup.slideUp(
            <SelectDeliveryPoint delivery_route={delivery_route}
                selected_delivery_point={selected_delivery_point}
                onDeliveryPointSelected={
                    (dpoint) => {
                        if(dpoint){
                            setSelectedDeliveryPoint(dpoint);
                            setSelectedDeliveryTimeRange(null);
                        }
                        popup.close();
                    }
                }
            />,
            {"title": "Select Delivery Location"}
        );
    }

    const showDeliveryTimesSelectionPopup = () => {
        var popup = Popup.slideUp(
            <SelectDeliveryTime
                delivery_route={delivery_route}
                delivery_time_ranges={delivery_route.delivery_time_ranges}
                selected_delivery_time_range={selected_delivery_time_range}
                selected_delivery_point={selected_delivery_point}
                onDeliveryTimeRangeSelected={
                    (delivery_time_range) => {
                        setSelectedDeliveryTimeRange(delivery_time_range);
                        popup.close()
                    }
                }
            />,
            {"title": "Select Delivery Slot"}
        );
    }

    const openBuyForUserCart = () => {
        setShowCart(true);
        broadcaster.broadcast_event("chat:cs_activity", session_data);
    }

    const openDeliveryRouteSelect = (delivery_routes, onSelect) => {
        const popup = Popup.slideUp(
          <SelectDeliveryRoute delivery_routes={delivery_routes} onSelect={(delivery_route) => {
            onSelect(delivery_route._id)
            setDeliveryRouteId(delivery_route._id)
            popup.close();
          }} />,
          {title: 'Select Delivery Route'}
        )
    }

    const selectDeliveryRouteId = () => {
        return new Promise((resolve, reject) => {
            if (delivery_route_id) {
                resolve(delivery_route_id)
            } else if (delivery_routes?.length > 0) {
                openDeliveryRouteSelect(delivery_routes, resolve);
            } else {
                setLoading(true);
                axios.get("/api/admin/init/").then(
                    (resp) => {
                        if(resp.data.errors){
                            GenericException.showPopup(resp.data.errors);
                            return;
                        }
                        if (resp.data?.delivery_routes_short) {
                            setDeliveryRoutes(resp.data.delivery_routes_short);
                            openDeliveryRouteSelect(resp.data.delivery_routes_short, resolve)
                        }
                    }
                )
                .catch(reject)
                .finally(
                    () => {
                        setLoading(false);
                    }
                );
            }
        })
    }

    const buyForUser = () => {
        selectDeliveryRouteId().then(openBuyForUserCart);
    }

    /* Utility functions */
    session_data.scanForItems = (ai_scan_items_txt) => {
        selectDeliveryRouteId().then((delivery_route_id) => {
            if (is_loading) return;
            setLoading(true);
            axios.post(
                `/api/cart/diff/${delivery_route_id}`,
                {
                    buying_for_user_id: user_id,
                    ai_scan_items_txt,
                    cart_id: cart?._id || null
                }
            ).then(
                (resp) => {
                    if(resp.data.errors){
                        if (resp.data.errors.no_ai_cart) {
                            Popup.toast(
                                <div className='tw-rounded tw-p-2 tw-text-sm'>{resp.data.errors.no_ai_cart}</div>,
                                2500
                            )
                        } else {
                            Popup.show("Errors", <GenericException ex={resp.data.errors} />);
                        }
                        return;
                    }
                    if (resp.data.cart) {
                        session_data.cart = resp.data.cart;
                        broadcaster.broadcast_event("cart_updated", resp.data.cart);
                        const {updated_items} = resp.data;
                        if (updated_items) {
                            const items_txt = Object.values(updated_items).map((item) => item.catalog_product.title).join(', ');
                            Popup.toast(
                                <div className='tw-rounded tw-p-2 tw-text-sm'>Added {Object.keys(updated_items).length} product(s) to the cart. - {items_txt}</div>,
                                2500
                            )
                        }
                        openBuyForUserCart();
                    }
                }
            )
            .finally(() => setLoading(false));
        });
    }

    useEffect(() => {
        if (cart?._id) {
            session_data.cart = cart;
        }
    }, [cart])

    const cart_items_length = Object.values(cart?.items || {}).filter(item => item.diff_qty > 0).length;

    return (
        <div className='tw-z-10'>
            <div className='tw-flex tw-flex-row tw-flex-wrap tw-text-center tw-text-white tw-text-xs tw-cursor-pointer tw-items-center'>
                <div className='tw-grow tw-px-4 tw-py-1 tw-bg-secondary tw-whitespace-nowrap tw-relative'
                    onClick={buyForUser}
                >
                    Buy for Customer&nbsp;

                    <FontAwesomeIcon icon={faCartShopping} className='tw-float-right' />
                    {cart_items_length > 0
                    ?   <div className='tw-absolute tw-bg-green-500 tw-rounded-full tw-p-1 tw-font-bold tw-leading-[8px] tw--top-2 tw-right-0 tw-text-xs'>
                            {cart_items_length}
                        </div>
                    :   null
                    }
                </div>
                <CsSupportChatButtons user_id={user_id} 
                    session_data={session_data}
                    delivery_route_id={delivery_route_id}
                    onChatResolved={onChatResolved} 
                />
            </div>
            <ResponsivePopup
                show={is_show_cart && delivery_route && session_data} 
                onClose={() => setShowCart(false)}
                container={session_data.chat_container_el}
                style_props={{md_max_width: '90%'}}
                titleRenderer={() => <BfcCartTitle
                    title={buying_for_user ? `${buying_for_user?.name}'s Cart` : 'Review Cart'} 
                    onClose={() => setShowCart(false)}  
                    onViewOrders={() => {setShowCart(false); setShowOrders(true)}}
                />}
            >
                <>
                <CartAndOrderView 
                    order={order}
                    cart={cart} 
                    delivery_route={delivery_route} 
                    buying_for_user={buying_for_user} 
                    user={user}
                    onRefresh={fetchCart}
                    is_from_bfc={true}
                    onDeliveryPointSelect={showDeliveryPointsSelectionPopup} 
                    onDeliveryTimeSelect={showDeliveryTimesSelectionPopup} 
                    container={session_data.chat_container_el}
                />
                {is_loading ? <LoadingOverlay title={'Loading...'} height={ViewPort.HEIGHT} /> : null}
                </>
            </ResponsivePopup>
            <ResponsivePopup
                show={is_show_orders && delivery_route && session_data} 
                onClose={() => setShowOrders(false)}
                container={session_data.chat_container_el}
                style_props={{md_max_width: '90%'}}
                title={buying_for_user ? `${buying_for_user?.name}'s Orders` : 'All Orders'} 
            >
                <UserOrders
                    delivery_route={delivery_route} 
                    buying_for_user={buying_for_user} 
                    user={user}
                    container={session_data.chat_container_el}
                />
            </ResponsivePopup>
            {is_loading && !is_show_cart ? <LoadingOverlay title={'Loading...'} height={ViewPort.HEIGHT} /> : null}
        </div>
        
    );
}


/* 
	Chat support buttons
	- Resolve chat
	- Toggle bot active
	- Force take over customer support
*/
function CsSupportChatButtons({
	user_id, session_data, onChatResolved,
    delivery_route_id,
}){
    const delivery_route_user = session_data.delivery_route_user || {};
    delivery_route_id = delivery_route_id || delivery_route_user.delivery_route_id;
	const [is_page_visible] = useBroadcastedState("visibility_changed", true);

	const [needs_resolve, _setNeedsResolve] = useState(delivery_route_user.needs_resolve);
	const [is_bot_active, setIsBotActive] = useState(delivery_route_user.is_bot_active);

	const [cs_staff_id, setCsStaffId] = useState(delivery_route_user.cs_staff_id);
	const [cs_staff_user, setCsStaffUser] = useState(null);
	const last_cs_active_at = delivery_route_user.cs_active_at || 0;

	const ctx = useRef({}).current;
	const user = useCurrentUser();

	const setNeedsResolve = (needs_resolve) => {
		_setNeedsResolve(needs_resolve);
		delivery_route_user.needs_resolve = needs_resolve;
	}

	const ping_cs_active = (force) => {
		if(delivery_route_user.last_cs_ping_at > now_millis - 5 * 1000) return;
		delivery_route_user.last_cs_ping_at = now_millis;
		const last_user_im_created_at = last(
			session_data.inbox_messages,
			(im) => {return im.src_id === user_id && im._type === INBOX_MESSAGE_TYPE_WA_INCOMING_MSG}
		)?.created_at;

		axios.post(
			`/api/admin/users/${delivery_route_id}`,
			{
				"user_id": user_id, "action": "cs_active","force": force,
				"last_user_im_created_at": last_user_im_created_at
			}
		).then((resp) => {
			if(defined(resp.data.needs_resolve)) setNeedsResolve(resp.data.needs_resolve);
			setCsStaffId(resp.data.cs_staff_id);
		});
	}

	useEffect(
		() => {
			if(!cs_staff_id) return;
			getByIds({"user_ids": [cs_staff_id]}).then((cache) => {
				setCsStaffUser(cache.users[cs_staff_id]);
			});
		}, 
		[cs_staff_id]
	);

	const mark_cs_inactive = () => {
		axios.post(
			`/api/admin/users/${delivery_route_id}`,
			{"user_id": user_id, "action": "cs_inactive"}
		);
	}

	const resolveChat = () => {
		axios.post(
			`/api/admin/users/${delivery_route_id}`,
			{"user_id": user_id, "action": "resolve"}
		).then((resp) => {
			setNeedsResolve(resp.data.needs_resolve);
			onChatResolved && onChatResolved(resp.data.needs_resolve);
		});
	}  
	
	const _onCsChatActivity = (session_data) => {
		if(!session_data.session._id.startsWith("wcs_")) return;
		ctx.is_cs_active = true;
		ping_cs_active();
		setIsBotActive(false);
		if(ctx.cs_active_timer) clearInterval(ctx.cs_active_timer);
		/* */
		ctx.cs_active_timer = setInterval(() => ctx.is_cs_active && ping_cs_active(), 10 * 60 * 1000);
	}

	const toggleBotActive = () => {
		if (!is_bot_active) {
			mark_cs_inactive();
			ctx.is_cs_active = false;
			delivery_route_user.is_bot_active = true;
		} else {
			ping_cs_active();
			ctx.is_cs_active = true;
			delivery_route_user.is_bot_active = false;
		}  
		setIsBotActive(delivery_route_user.is_bot_active);
	}

	useEffect(
		() => {
			const onCsChatTyping = (session_data, el) => _onCsChatActivity(session_data);
			const onCsMessageSent = (im, session_data) => _onCsChatActivity(session_data);
			broadcaster.add_event_listener("chat:typing", onCsChatTyping);
			broadcaster.add_event_listener("chat:message_sent", onCsMessageSent);
			broadcaster.add_event_listener("chat:cs_activity", _onCsChatActivity); 
			return () => {
				clearInterval(ctx.cs_active_timer);
				broadcaster.remove_event_listener("chat:typing", onCsChatTyping);
				broadcaster.remove_event_listener("chat:message_sent", onCsMessageSent);
				broadcaster.remove_event_listener("chat:cs_activity", _onCsChatActivity);
			}
		}, []
	);

	useEffect(() => {ctx.is_cs_active && ping_cs_active()}, [is_page_visible]);


	const now_millis = new Date().getTime();
	return <>
		{
			needs_resolve
			?   <div className='tw-grow tw-px-4 tw-py-1 tw-bg-black'
					onClick={resolveChat}
				>☐ Resolve Chat</div>
			:   null
		}
		<div className={`tw-shrink-0 tw-px-4 tw-py-1 tw-bg-blue-500 ${!needs_resolve ? 'tw-min-w-[30%]' : ''}`}
			onClick={() => toggleBotActive()}
		>
			{!is_bot_active ? "☐" : "✅"} BOT
		</div>
		{
			/* recently active by another user */
			last_cs_active_at > now_millis - 5 * 60 * 1000 && cs_staff_user && user && cs_staff_user._id !== user._id
			?   <div className='tw-bg-red-600 tw-flex tw-grow tw-items-center tw-flex-row tw-clear-both'>
					<div className='tw-pl-2'>{cs_staff_user.name} is handling this chat</div>
					<div className='tw-p-1 tw-pr-4 tw-ml-auto' onClick={() => ping_cs_active(true)}>Force</div>
				</div> /* change it to tailwind */
			: null
		}
	</>
}



/* 
    This is the default bottom bar for user chats on mobilefresh portal, irrespective if they are using
    routes or not
*/
DeliveryRouteUserBottomChatOptions.render = (session_data, onChatResolved, delivery_route_id) => {
    const session_id = session_data.session._id
    if(!session_id.startsWith("wcs_")) return null;
    if(session_data.delivery_route_user){
        return <DeliveryRouteUserBottomChatOptions 
            user_id={session_id.split("_")[1]}
            session_data={session_data}
            delivery_route_id={delivery_route_id}
            onChatResolved={onChatResolved}
        />
    } else if(session_data.org_user){
        return <OrgUserChatSupportButtons 
            org_id={session_data.delivery_org_id}
            session_data={session_data}
            user_id={session_id.split("_")[1]}
            onChatResolved={onChatResolved}
        />
    }
}

/* 
    This is the default bottom bar for user chats on mobilefresh portal, irrespective if they are using
    routes or not
*/
DeliveryRouteUserBottomChatOptions.render = (session_data, onChatResolved, delivery_route_id) => {
    const session_id = session_data.session._id
    if(!session_id.startsWith("wcs_")) return null;
    if(session_data.delivery_route_user && !session_data.org_user?.cs_unseen_count){
        return <DeliveryRouteUserBottomChatOptions 
            user_id={session_id.split("_")[1]}
            session_data={session_data}
            delivery_route_id={delivery_route_id}
            onChatResolved={onChatResolved}
        />
    } else if(session_data.org_user){
        return <OrgUserChatSupportButtons 
            org_id={session_data.delivery_org_id}
            session_data={session_data}
            user_id={session_id.split("_")[1]}
            onChatResolved={onChatResolved}
        />
    }
}

export default DeliveryRouteUserBottomChatOptions
