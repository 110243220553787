import axios from 'axios';
import React, { useState } from 'react'
import { DateView } from 'base/ui/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faRefresh} from '@fortawesome/free-solid-svg-icons';
import ResponsivePopup from '../../../ui/ResponsivePopup';
import { GenericException } from 'base/ui/errors';
import { currencyToSymbol } from 'base/utils/common';
import { ChatSession } from 'base/ui/chat';
import { useCurrentUser } from 'base/app';
import CartAndOrderView from '../../store/CartAndOrderView';
import { getDisplayPrice, getPrice } from '../../../../helpers/StoreHelper';
import DeliveryRouteUserBottomChatOptions from '../../customers/DeliveryRouteUserBottomChatOptions';
import { ORDER_STATUS_DELIVERED, ERP } from '../../../../constants';
import useCheckout from '../../store/useCheckout';
import { useBroadcastedState } from 'base/utils/events';
import { EditUserById } from '../../../ui/commonUI';
import { Popup } from 'base/ui/popups';
import SelectDeliveryUser from '../../store/SelectDeliveryUser';
import { matchPath, useLocation } from 'react-router-dom';
import { LoadingOverlay, LoadingView } from 'base/ui/status';


function ThirdPartyDetails({order, setOrder, delivery_route}){
    const [is_loading, setIsLoading] = useState()
    const to_pay = getPrice(delivery_route.currency, (order.total_amount - order.total_paid));
    const delivery_point = delivery_route.delivery_points?.find((d_point) => d_point.name === order.delivery_point_name)
    const disable_thirdparty_sync = to_pay > 0 && (delivery_point?.prepayment_percentage
        ? delivery_point?.prepayment_percentage == 100
        : delivery_route.prepayment_percentage == 100)

    const syncThirdPartyOrder = () => {
        setIsLoading(true)
        if (order.third_party_platform === "woocommerce") {
            const payload = {
                delivery_route_id: delivery_route._id,
                order_ids: [order._id]
            }
            axios.post("/woo/sync/orders/" + delivery_route.delivery_org._id, payload).then(
                (resp) => {
                    if (resp.data.errors) {
                        GenericException.showPopup(resp.data.errors);
                    }
                    if (resp.data.orders) {
                        setOrder(resp.data.orders?.[0])
                    }
                }
            ).finally(
                () => setIsLoading(false)
            )
        } else if ([ERP.ERPNEXT.id, ERP.MAGENTO.id, ERP.SHOPIFY.id].includes(order.third_party_platform)) {
            axios.post(`/api/admin/delivery_route/${delivery_route._id}/order/${order._id}/sync_to_erp`).then(
                (resp) => {
                    if (resp.data.errors) {
                        GenericException.showPopup(resp.data.errors);
                    }
                    if (resp.data.order) {
                        setOrder(resp.data.order)
                    }
                }
            ).finally(
                () => setIsLoading(false)
            )
        }
    }

    return (
        <div className=' tw-text-xs tw-flex tw-justify-between tw-items-center'>
            <div className=''>{"Created From →"}
                <span className='tw-font-bold tw-ml-1 tw-text-[#96bf48]'>{order.third_party_order_origin || "Sukhiba"}</span>
                {
                    order.third_party_extra_data?.last_synced_at && <div className='tw-text-xxs tw-mt-1'>
                        Last Sync at: <DateView className={`${order.third_party_extra_data.is_sync_failed ? "tw-text-red-600" : "tw-text-green-600"}`} millis={order.third_party_extra_data.last_synced_at} />
                    </div>
                }
            </div>
            <div className='tw-justify-end tw-items-center tw-z-20 tw-text-xss'>
                {order.third_party_order_id ? <p className='tw-text-end'>#{order.third_party_order_id}</p> : null}
                {([ERP.ERPNEXT.id, ERP.MAGENTO.id, "woocommerce", "shopify"].includes(order.third_party_platform)) &&
                    <div className={`tw-flex tw-items-center tw-gap-1 tw-cursor-pointer tw-border tw-rounded tw-p-1 tw-border-grey tw-mt-1 ${disable_thirdparty_sync && 'tw-text-gray-400'}`}
                        onClick={(evt) => {
                            evt.stopPropagation()
                            !disable_thirdparty_sync && syncThirdPartyOrder()
                        }} >
                        <FontAwesomeIcon className={` ${is_loading && "tw-animate-spin"}`} icon={faRefresh} />
                        <span>Sync with {order.third_party_platform === "woocommerce" ? "Woo" : order.third_party_platform}</span>
                    </div>
                }
            </div>
        </div>
    )
}

function LogisticDetails({ order, delivery_route }) {
    const [is_loading, setIsLoading] = useState()

    
    return (
        <div className=' tw-text-xs tw-flex tw-justify-between tw-items-center'>
            <div className=''>
                <span className='tw-font-bold tw-text-[#96bf48] tw-capitalize'>{order.carrier_id || "Sukhiba"}</span>
                {
                    order.carrier_order_status
                        ? <div className='tw-text-xxs tw-mt-1'>
                            Status: {order.carrier_order_status}
                        </div>
                        : null
                }
            </div>
            <div className='tw-justify-end tw-items-center tw-z-20 tw-text-xss'>
                {order.carrier_order_id ? <p className='tw-text-end'>#{order.carrier_order_id}</p> : null}                
            </div>
        </div>
    )
}

function OrderDeliveryViewShort({order:_order, delivery_route, refetchOrders, className, container, buying_for_user, is_deleted_order}){
    const [cart] = useBroadcastedState("cart_updated")
    const [order, setOrder] = useState(_order);
    const [is_show_order_details, setShowOrderDetails] = useState(false);
    const to_pay = order.total_amount - order.total_paid;
    const user = useCurrentUser();
    const { pathname } = useLocation();
    const is_delivery_route_path = matchPath("/delivery/:delivery_route_id/*", pathname);
    const [is_loading, setIsLoading] = useState()
    const shiprazor_warehouse_id = delivery_route?.other_config?.shiprazor_warehouse_id


    /* Checkout Hook */
    useCheckout({cart, delivery_route, buying_for_user});

    const updateOrder = (_order) => {
        setOrder(_order);
        if (_order.status === ORDER_STATUS_DELIVERED) { // TODO: test this
            setShowOrderDetails(false);
        }
    }

    function assignRider() {
        const popup = Popup.show("Assign Rider", <SelectDeliveryUser delivery_route={delivery_route}
            onDeliveryUserSelected={(user) => {
                assignUserToOrder(user)
                popup.close()
            }} />)
    }

    function assignUserToOrder(user) {
        const order_ids = [order._id];
        const payload = {
            "action": "assign_delivery",
            "order_ids": order_ids
        }
        setIsLoading(true)
        axios.post(
            `/api/admin/orders/${delivery_route._id}?delivery_person_id=${user._id}`,
            payload,
        ).then(
            (resp) => {
                if (resp.data.errors) {
                    Popup.show("Error", <GenericException ex={resp.data.errors} />);
                    return;
                }
                setOrder(resp.data.orders?.[0])
            }
        ).finally(() => setIsLoading(false));
    }

    function assignToLogistics(sync_to) {
        const order_ids = [order._id];
        const payload = {
            "order_ids": order_ids
        }
        setIsLoading(true)
        axios.post(
            `/api/admin/delivery_org/${delivery_route.delivery_org._id}/orders/${sync_to}`,
            payload,
        ).then(
            (resp) => {
                if (resp.data.errors) {
                    Popup.show("Error", <GenericException ex={resp.data.errors} />);
                    return;
                }
                // setOrder(resp.data.orders?.[0])
            }
        ).finally(() => setIsLoading(false));
    }

    const order_user = buying_for_user || order.user;

    return (
        <div className={className || 'tw-rounded-sm tw-bg-white tw-drop-shadow'}>
            {/* user */}
            <div className="tw-relative tw-cursor-pointer" onClick={() => {setShowOrderDetails(true)}}>
                {
                    is_loading ? <LoadingOverlay title={"Please wait"} /> : null
                }                
                <div className='tw-p-3 tw-grid tw-gap-4' style={{gridTemplateColumns: 'repeat(auto-fit, minmax(120px, max-content))'}}>
                    <div className='tw-flex' onClick={
                            (evt)=> evt.stopPropagation() 
                                || ChatSession.open(
                                    `wcs_${order_user?._id}_${delivery_route.delivery_org.wa_business_number}`,
                                    {'bottomStatusIndicator': DeliveryRouteUserBottomChatOptions.render, "polling": true}
                                )
                        }
                    >
                        <div className='tw-w-8 tw-h-8 tw-rounded-full flex-box tw-text-primary tw-bg-primary-fade tw-mr-2 tw-flex-none'>
                            <span>{order_user?.name?.slice(0,1)}</span>
                        </div>
                        <div>
                            <div className='tw-text-sm'>{order_user?.name}</div>
                            <div className='tw-text-xs tw-text-gray-500 tw-mt-1'>#{order._id}</div>
                        </div>
                    </div>
                    <div className='tw-ml-8'>
                        <div className='tw-text-sm'>Phone/Email</div>
                        <div className='tw-text-xs tw-text-gray-500 tw-mt-1'>
                            {order_user?.phone_number || order_user?.email_id}
                            </div>
                    </div>
                    <div className='tw-ml-8'>
                        <div className='tw-text-sm'>Status</div>
                        <div className='tw-text-xs tw-text-gray-500 tw-mt-1'>
                            {
                                order.status 
                                ? <div className='tw-text-green-600'>Delivered Completed</div>
                                : <div className='tw-text-red-600'>{is_deleted_order ? 'Cancelled' : 'To Be Delivered'}</div>
                            }
                        </div>
                    </div>
                    <div className='tw-ml-8'>
                        <div className='tw-text-sm'>Products</div>
                        <div className='tw-text-xs tw-text-gray-500 tw-mt-1'>{Object.keys(order.items)?.length}</div>
                    </div>
                    <div className='tw-ml-8'>
                        <div className='tw-text-sm'>Total</div>
                        <div className='tw-text-xs tw-text-gray-500 tw-mt-1'>{order.total_amount === null ? '-' : getDisplayPrice(delivery_route.currency, order.total_amount)}</div>
                    </div>
                    <div className='tw-ml-8'>
                        <div className='tw-text-sm'>To Pay</div>
                        {
                            to_pay > 10
                            ?   <div className='tw-text-xs tw-text-gray-500 tw-mt-1'>{getDisplayPrice(delivery_route.currency, to_pay)}</div>
                            :   <div className='tw-text-xs tw-text-green-500 tw-mt-1'>{order.total_amount === null ? '-' : 'Paid'}</div>
                        }
                    </div>
                    {order.purchased_by_user
                        ? <div className='tw-ml-8'>
                            <div className='tw-text-sm'>Purchased By</div>
                            <div className='tw-text-xs tw-text-gray-500 tw-mt-1'>{order.purchased_by_user?.name}</div>
                        </div>
                        : null
                    }
                    {
                        shiprazor_warehouse_id
                            ? !order.status && !order.carrier_order_id
                                ? <div className='tw-ml-8'>
                                    <div className='tw-text-sm'>Shiprazor</div>
                                    <div className='tw-text-xs tw-text-gray-500 tw-mt-1' onClick={(e) => {
                                        e.stopPropagation()
                                        assignToLogistics("sync_to_shiprazor")
                                    }} >Click to Send</div>
                                </div>
                                : null
                            : !is_delivery_route_path && !order.status
                                ? order.delivery_person_id
                                    ? <div className='tw-ml-8'>
                                        <div className='tw-text-sm'>Assigned To</div>
                                        <EditUserById className='tw-text-xs tw-text-gray-500 tw-mt-1' user_id={order.delivery_person_id} onEdit={() => {
                                            assignRider()
                                        }} />
                                    </div>
                                    : <div className='tw-ml-8'>
                                        <div className='tw-text-sm'>Assigned To</div>
                                        <div className='tw-text-xs tw-text-gray-500 tw-mt-1' onClick={(e) => {
                                            e.stopPropagation()
                                            assignRider()
                                        }} >Click to Assign</div>
                                    </div>
                                : null
                    }
                    {order.deleted_by_user
                        ? <div className='tw-ml-8'>
                            <div className='tw-text-sm'>Cancelled By</div>
                            <div className='tw-text-xs tw-text-gray-500 tw-mt-1'>{order.deleted_by_user?.name}</div>
                        </div>
                        : null
                    }
                    {order.deleted_reason
                        ? <div className='tw-ml-8'>
                            <div className='tw-text-sm'>Cancelled Reason</div>
                            <div className='tw-text-xs tw-text-gray-500 tw-mt-1'>{order.deleted_reason}</div>
                        </div>
                        : null
                    }
                </div>
                {
                    order.third_party_platform &&
                    <div className='tw-p-3 tw-border-t'>
                        <ThirdPartyDetails order={order} setOrder={setOrder} delivery_route={delivery_route} />                  
                    </div>
                }
                {
                    order.carrier_id &&
                    <div className='tw-p-3 tw-border-t'>
                        <LogisticDetails order={order} delivery_route={delivery_route}/>                  
                    </div>
                }
                <div className='tw-p-3 tw-border-t tw-text-xs tw-relative w3-flex w3-flex-vcenter'>
                    <div>
                        {
                            order.address
                            ? <div className='tw-text-gray-600 tw-mb-1'><b>Delivery: &nbsp;</b> 
                                <span className='tw-text-gray-500'>{order.address}</span>
                            </div>
                            : null
                        }
                        {
                            order.delivery_point_name
                            ? <div className='tw-text-gray-600 tw-mb-1'><b>Delivery Location: &nbsp;</b> 
                                <span className='tw-text-gray-500'>{order.delivery_point_name}</span>
                            </div>
                            : null
                        }
                        {
                            order.created_at
                            ? <div className='tw-text-gray-600 tw-mb-1'><b>Ordered Date: &nbsp;</b> 
                                <span className='tw-text-gray-500'>
                                    <DateView millis={order.created_at} exclude_time={false} />
                                </span>
                            </div>
                            : null
                        }
                        {
                            order.delivery_time
                            ? <div className='tw-text-gray-600'><b>Delivery Time: &nbsp;</b> 
                                <span className='tw-text-gray-500'>
                                    <DateView millis={order.delivery_time} />
                                </span>
                            </div>
                            : null
                        }
                        {order.instructions?.delivery
                            ?   <div className='tw-text-gray-600 tw-mt-1.5 tw-pt-1.5 tw-border-t tw-border-dashed'><b>Delivery Instructions: &nbsp;</b> 
                                    <span className='tw-text-gray-500'>
                                        {order.instructions?.delivery}
                                    </span>
                                </div>
                            : null
                        }
                    </div>
                    <FontAwesomeIcon icon={faChevronRight} className='tw-text-sm w3-margin-left-auto' />
                </div>
            </div>
            <ResponsivePopup onClose={() => setShowOrderDetails(false)}
                show={is_show_order_details} title={'View Order'}
                container={container}
            >
                {
                    order.third_party_platform &&
                    <div className='tw-p-3 tw-border-b'>
                        <ThirdPartyDetails order={order} setOrder={setOrder} delivery_route={delivery_route} />
                    </div>
                }
                <CartAndOrderView
                    cart={cart}
                    order={order} 
                    is_deleted_order={is_deleted_order}
                    delivery_route={delivery_route} 
                    user={user} 
                    buying_for_user={order_user} 
                    onRefresh={refetchOrders}
                    onOrderUpdate={updateOrder}
                    container={container}
                />
            </ResponsivePopup>
        </div>
    )
}

export {OrderDeliveryViewShort}
