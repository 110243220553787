import { faCheckCircle, faDownload, faSearch, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Popup } from "base/ui/popups";
import { EmptyView, LoadingOverlay, LoadingView } from "base/ui/status";
import { loadGoogleMapsScript, useRerender } from "base/utils/common";
import { useOnScroll } from "base/ui/utils";
import AddNewCustomer from "./AddNewCustomer";
import DeliveryRouteUser from "./DeliveryRouteUser";
import { useOutletContext } from "react-router-dom";
import BackHeader from "../../layout/BackHeader";
import { useBroadcastedState } from "base/utils/events";
import { SuggestedField } from "base/ui/suggested_field";
import { UserBadge } from "../../ui/commonUI";

function getTags(delivery_route){
  return [
    ...new Set(delivery_route?.delivery_points?.map((cp) => cp.area).filter((area) => area)),
    ...new Set(delivery_route?.data?.additional_tags?.map(tag => tag?.trim()).filter((tag) => tag))
];
}

function CustomersList({is_select_list, selected_users = [], setSelectedUsers}){
    const [delivery_data] = useBroadcastedState('delivery_route_data');
    const { delivery_route } = useOutletContext() || delivery_data;
    const [search_result_users, setSearchResultUsers] = useState([]);
    const scrollable_el_ref = useRef();
    const rerender = useRerender();
    const ctx = useRef({}).current;
    const [screen] = useBroadcastedState("tw_screen_size");

    const [search_text, setSearchText] = useState("");
    const [selected_tags, setSelectedTags] = useState([]);
    const [filter, setFilter] = useState(null);
        
    if(!delivery_route.users){
      delivery_route.users = [];
    }
    const users = delivery_route.users;
  

    const setIsLoading = (val) => { 
      ctx.is_users_loading = val;
      rerender();
    }

    const fetchMoreUsers = useCallback(() => {      
      if (
        !delivery_route.has_more_users
        || ctx.is_users_loading
        || search_result_users.length > 0
      ) {
        return
      }
      setIsLoading(true);
      axios.post(
        `/api/admin/users/${delivery_route._id}`,
        { "cursor": delivery_route.next_users_cursor }
      ).then(
        (resp) => {
          if (resp.data.errors) {
            return;
          }
          users.push(...resp.data.users);
          delivery_route.next_users_cursor = resp.data.next_users_cursor;
          delivery_route.has_more_users = resp.data.has_more_users;
          rerender();
        }
      ).finally(
        () => {
          setIsLoading(false);
        }
      )
    },[users])
  
  const searchUsers = () => {    
    if (ctx.is_users_loading) {
      return
    }
    if (!search_text && selected_tags.length == 0 && !filter) {
      setSearchResultUsers([]);
      return;
    }
    setIsLoading(true);
    const payload = { "action": "search", ...filter }

    if (search_text)
      payload["search_text"] = search_text
    if (selected_tags.length > 0)
      payload["tags"] = selected_tags
    if (delivery_route.has_more_search_users)
      payload["cursor"] = delivery_route.next_search_users_cursor


    axios.post(
      `/api/admin/users/${delivery_route._id}`, payload
    ).then(
      (resp) => {
        if (resp.data.errors) {
          return;
        }
        if (delivery_route.has_more_search_users) {
          search_result_users.push(...resp.data.users);
        } else {
          setSearchResultUsers(resp.data.users)
        }
        delivery_route.next_search_users_cursor = resp.data.next_users_cursor;
        delivery_route.has_more_search_users = resp.data.has_more_users;
        delivery_route.search_users_count = resp.data.total        
      }
    ).finally(
      () => {
        setIsLoading(false);
      }
    )
  };

    useEffect(() => {
      delivery_route.search_users_count = undefined
      delivery_route.has_more_search_users = false
      if (selected_tags.length > 0 || filter !== null) {
        searchUsers();
      } else {
        setSearchResultUsers([])
        setSearchText("")
      }
    }, [selected_tags, filter]);
    
    useEffect(() => {
      if (!search_text && selected_tags.length == 0) {
        setSearchResultUsers([]);
        return;
      }
      
      const timeout = setTimeout(
        () => {
          delivery_route.search_users_count = undefined
          delivery_route.has_more_search_users = false    
          searchUsers();
        }, 500
      );
      return () => clearTimeout(timeout);
    }, [search_text]
    );

    useEffect(() => {rerender()}, [ctx.is_users_loading]);

    useOnScroll(scrollable_el_ref.current, (percent) => {
      percent == 100 && loadMoreCustomers()
    }, [delivery_route, fetchMoreUsers, searchUsers]);

    function loadMoreCustomers(){
      if (search_result_users.length > 0 && delivery_route.has_more_search_users) {
        searchUsers() //pagination for search reasults
        return
      }else{
        fetchMoreUsers()
      }
    }

    const setScrollRef = (el) => {
      if (!scrollable_el_ref.current) {
        scrollable_el_ref.current = el;
        rerender();
      }
    }
    
    const addCustomer = () => {
      var popup = Popup.show(
        "Add Customer",
        <AddNewCustomer
          delivery_route={delivery_route}
          onSave={
            (user) => {
              users.unshift(user);
              delivery_route.users_count += 1;
              rerender();
              popup.close();
            }
          }
        />
      );
    }

  const onUserUpdated = (user) => {
    const index = users.findIndex(_user => _user._id === user._id);
    if (index >= 0) {
      users[index] = user;
      if (!search_result_users)
        rerender();
    }
    if (search_result_users) {
      const index = search_result_users.findIndex(_user => _user._id === user._id);
      if (index >= 0) {
        search_result_users[index] = user;
        rerender();
      }
    }
  }
  
    if(!users){
      return <EmptyView title="Loading.." height="300px" />
    }
    const is_user_shown = new Set();

    // Loading maps here first to keep it ready for add customer
    useEffect(() => {
      loadGoogleMapsScript('AIzaSyCSMLgYj7Uxz34Afcvclun1FslKy6ILtwI')
    }, [])

    const users_list = search_result_users.length ? search_result_users : users.filter(
      (duser) => {if(is_user_shown.has(duser._id)) return false; is_user_shown.add(duser._id); return true; }
    )

    return (
      <div className={`${is_select_list ? '' : 'lg:tw-w-[60%]'} lg:tw-border-x tw-flex tw-flex-col max-lg:full-height `}>
          {!is_select_list
            ? <div className="hflex tw-pr-4 lg:tw-border-b max-lg:tw-bg-primary">
                <div className="tw-grow">
                  {screen.lg ? 
                    <div className="tw-pl-4 tw-py-4">
                      <div className="tw-text-md">Customers 
                        <span className='tw-rounded tw-bg-secondary tw-text-gray-200 tw-text-sm tw-px-2 tw-py-[2px] tw-ml-2'>
                          {delivery_route.search_users_count || delivery_route.users_count}
                        </span>
                      </div>
                    </div>
                    : <BackHeader title={`Customers (${delivery_route.search_users_count || delivery_route.users_count})`} />
                  }
                </div>
                <a href={`/download/users/?delivery_route_id=${delivery_route._id}`} target="_blank" rel="noreferrer"
                  className="tw-text-sm lg:tw-border lg:tw-rounded lg:tw-px-4 tw-mx-2 tw-py-2 tw-text-gray-200 lg:tw-text-gray-600">
                  <FontAwesomeIcon icon={faDownload} />&nbsp; <span className="max-lg:tw-hidden">Download</span>
                </a>
                <button className='tw-text-white tw-bg-secondary tw-px-4 tw-py-2 tw-text-sm lg:tw-rounded max-lg:tw-absolute max-lg:tw-bottom-0 max-lg:tw-w-full tw-z-10'
                  onClick={addCustomer}
                >
                    <FontAwesomeIcon icon={faUserPlus} />
                    <span className='tw-text-xs'>&nbsp; Add</span>
                </button>
              </div>
            : null
          }
          <div className={`tw-relative tw-border-b ${is_select_list ? '' : 'max-lg:tw-bg-primary tw-pt-2 tw-pb-4 tw-px-6'}`}>
            <SuggestedField className="tw-py-3 tw-grow tw-bg-transparent tw-outline-0 tw-z-20"
              props={{
                "selection_list": getTags(delivery_route),
                "placeholder": "Search by name/phone number/tags",
                "result_renderer": (item) => <p className="tw-p-2">{item}</p>,
                "selected_renderer": (item, onRemove) => <div className="tw-bg-white tw-flex tw-px-1 tw-border tw-rounded tw-items-center tw-my-1 tw-mr-1">
                  <span className="tw-text-sm tw-text-center tw-text-secondary">{item}</span>
                  <span className='tw-text-lg tw-text-black-500 tw-pl-2 tw-cursor-pointer' onClick={onRemove}>&times;</span>
                  </div>,
                "show_results_on_render": false,
                "hide_no_results": true,
                "selected_values_classname": "tw-flex tw-border-0"
              }}
              selected={selected_tags}
              onSelect={(new_tags) => {
                setSelectedTags(new_tags)
              }}
              onSearch={(search_text) => {
                setSearchText(search_text)
              }}
              input_className={'tw-rounded tw-text-sm tw-border tw-w-full tw-pl-8 tw-pr-4 tw-py-2'}
            />   
             
            {!is_select_list
              ? 
                <>
                  <FontAwesomeIcon icon={faSearch} className="tw-text-gray-400 tw-absolute tw-top-8 tw-left-8 tw-z-20" /> 
                  <div className="tw-flex tw-items-center tw-gap-4 tw-mt-2">
                    <button className={`tw-border ${!filter ? 'tw-border-secondary tw-bg-secondary tw-text-white' : 'tw-bg-white tw-border tw-rounded-full tw-px-3 tw-py-1 tw-text-xs'} tw-rounded-full tw-px-3 tw-py-1 tw-text-xs`}
                      onClick={() => setFilter(null)}
                    >
                      All Customers
                    </button>
                    <button className={`tw-border ${filter?.only_credit_customers ? 'tw-border-secondary tw-bg-secondary tw-text-white' : 'tw-bg-white tw-border tw-rounded-full tw-px-3 tw-py-1 tw-text-xs'} tw-rounded-full tw-px-3 tw-py-1 tw-text-xs`}
                      onClick={() => setFilter({...(filter || {}), only_credit_customers: true})}
                    >
                      Customers with Credit
                    </button>
                  </div> 
                </>
              : null
            }
          </div>
          {
            users && users.length
              ?   <div className={`tw-py-2 tw-overflow-auto tw-divide-y tw-grow tw-relative lg:customers-maxh ${is_select_list ? '' : 'max-lg:tw-pb-10'}`}
                    ref={setScrollRef}
                  >  
                    {
                      search_text && ctx.is_users_loading
                      ? <div className="tw-text-black tw-text-center tw-bg-gray-100 tw-sticky tw-mb-2">Searching..</div>
                      : null
                    }          
                    {
                      users_list.map(
                        (user) => {
                          const is_user_selected = is_select_list && selected_users?.includes(user._id);
                          return is_select_list
                          ? <div className="hflex" key={user._id} onClick={() => {setSelectedUsers(is_user_selected ? selected_users.filter(_id => _id !== user._id) : [...selected_users, user._id])}}>
                              <UserBadge user={user} />
                              {!is_user_selected
                                ? null
                                : <FontAwesomeIcon icon={faCheckCircle} className='tw-text-green-600' /> 
                              }
                            </div>
                          : <DeliveryRouteUser
                              user={user} delivery_route={delivery_route}
                              onUserUpdated={onUserUpdated}
                              key={user._id}
                            />
                        }
                      )
                    }
                    {
                      ctx.is_users_loading
                      ? <LoadingView height={75}></LoadingView>
                      : null
                    }  
                  </div>
              : <EmptyView title={"No Customers Yet"} height="200px" />
          }
      </div>
    );
}
export default CustomersList;
