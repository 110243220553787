import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBroadcastedState } from 'base/utils/events';
import React, { useEffect } from 'react'
import { createPortal } from 'react-dom';

const LoginPopup = ({show, onClose, children, zIndex, is_create_shop}) => {

    const [shop_info] = useBroadcastedState('shop_info');
    useEffect(() => {
        //Loading fonts only for login popup
        import('../../../css/landing.css')
    }, []);

    return (
        show 
            ?   createPortal(
                <div className={`tw-absolute tw-z-${zIndex || '50'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75"></div>
                    <div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
                        <div className="tw-flex tw-min-h-full tw-justify-center tw-w-full tw-items-center">
                            <div className='tw-bg-white tw-transform tw-transition-all md:tw-rounded-2xl tw-shadow-xl max-md:tw-w-full max-md:tw-h-screen tw-min-w-[55%] 2xl:tw-min-w-[40%] md:tw-max-w-[70%] w3-animate-bottom'>
                                <div className='tw-flex tw-h-full max-md:tw-flex-col'>
                                    <div className='tw-relative md:tw-rounded-l-2xl tw-bg-primary-dark tw-px-8 md:tw-pt-20 tw-flex md:tw-flex-col md:tw-justify-end md:tw-w-[45%]'>
                                        <div className='max-md:tw-pt-8 tw-z-10'>
                                            <img src={'https://storage.googleapis.com/sukhiba/mobilefresh/user_uploads/2023-08-05/1691219349JjjEK0ackr__Sukhiba_Connect_Side1_2.png'} className='tw-w-14' />
                                            <div className='max-md:tw-my-4 md:tw-my-8 tw-text-white tw-text-[30px] font-inter tw-whitespace-pre'>
                                                {
                                                    is_create_shop 
                                                    ?   <span>Let&apos;s set-up <br/><span className='tw-text-yellow-500'>your shop!</span></span>
                                                    :   <span>
                                                           Welcome to <br/>
                                                           <span className='tw-text-yellow-500'>{shop_info?.title || "Sukhiba Connect"}
                                                           </span>
                                                        </span>
                                                }
                                            </div>
                                        </div>
                                        <img className='max-md:tw-absolute max-md:tw-bottom-0 max-md:tw-opacity-[9%] max-md:tw-right-0' src={'https://storage.googleapis.com/sukhiba/mobilefresh/user_uploads/2023-08-05/1691220919wzUbJ4a2RJ__Logingrouppic.png'} />
                                    </div>
                                    <div className='tw-px-8 tw-py-4 tw-flex-1'>
                                        <div className='tw-text-right tw-w-full tw-text-gray-600 max-md:tw-absolute max-md:tw-top-4 max-md:tw-right-4 max-md:tw-text-white' onClick={onClose}><FontAwesomeIcon icon={faX} className='tw-p-2 tw--m-2' /></div>
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )
            :   null
    )
}

export default LoginPopup