import React, {useState, useRef, useEffect, useCallback} from 'react';
import { getKeyPath, isObject } from '../utils';
import { GenericException } from './errors';
import { CSS_COLOR } from './misc';
import { getUKey } from './utils';
import ReorderableList from './dnd_list';
import { broadcaster, useBroadcastedState } from 'base/utils/events';

/*
- You can set column, sizes in (l, m , s, t) for large, medium, small and tiny screens
- You can also set "pos" of each of these columns in (l, m, s, t) to reorder them on different screens
- You have a render function and and editor function for each column, to render and edit the column
*/
function EditableTable({cols:_cols, rows, callbacks, actions, className, L=1024, M=768, S=480, options:_options}){
    const [width, setWidth] = useState(0);
    const [cols, setCols] = useState([]);
    rows = rows || [];
    const container_el = useRef(null);
    const [is_table, setIsTable] = useState(true);
    const options = useRef(_options || {}).current;
    useEffect(
        () => {
            const onResize = () => setWidth(container_el.current?.offsetWidth || 0);
            onResize(); // initial resize
            window.addEventListener("resize", onResize);
            return () => window.removeEventListener("resize", onResize);
        }, []
    );
    useEffect(
        () => {
            if(!width || !_cols) return;
            let cols = _cols.filter(col => col);
            /* action columns */
            if(actions || callbacks?.onUpdate || callbacks?.onDelete){
                cols.push({ // Actions Column
                    "render": (row) => <ActionsColumn row={row} callbacks={callbacks} actions={actions} />,
                    "editor": (row, updates) => {
                        if(!callbacks?.onUpdate) return undefined;
                        return (
                            row.__is_editing
                            ? <EditingActionsColum row={row} callbacks={callbacks} updates={updates} />
                            : undefined
                        )
                    },
                    "size": options.actions_size || {"s": 100}
                });
            }
            /* col width in percentages */
            for(let i = 0; i < cols.length; i++){
                const col = cols[i];
                col.size_in_percentage = {};
                for(let [_type, value] of Object.entries(col.size || {})){
                    if(typeof value === "number") col.size_in_percentage[_type] = value;
                    if(typeof value === "string"){
                        if(value.endsWith("%")) col.size_in_percentage[_type] = parseFloat(value);
                        else if(value.endsWith("px")) col.size_in_percentage[_type] = (parseFloat(value) / width * 100);
                        else if(value.endsWith("em")) col.size_in_percentage[_type] = (parseFloat(value) * 16 / width * 100).toFixed(2);
                    }
                }
            };

            let avg_table_col_width = null;
            if(width >= L){
                /* if large, try to disply as a table using up remaining width */
                const cols_with_size = cols.filter(col => col.size_in_percentage["l"]);
                const total_width = cols_with_size.reduce((acc, col) => acc + col.size_in_percentage["l"], 0);
                let remaining_width = (99.99 - total_width);
                if(remaining_width < 0) remaining_width = 100;
                avg_table_col_width = remaining_width / (cols.length - cols_with_size.length); // +1 for actions
            }

            for (let i = 0; i < cols.length; i++) {
                const col = cols[i];
                let {l, m , s, t} = col.size_in_percentage || {};
                if(l === undefined) l = avg_table_col_width;
                if(m === undefined) m = 50;
                if(s === undefined) s = 50;
                if(t === undefined) t = 100; //tiny
                col._size = width >= L ? l: (width >= M ? m : (width >= S ? s : t));
            }
            /* positions */
            for (let i = 0; i < cols.length; i++) {
                const col = cols[i];
                let {l, m , s, t} = col.position || {};
                if(l === undefined) l = i;
                if(m === undefined) m = i;
                if(s === undefined) s = i;
                if(t === undefined) t = i;
                let _pos = width >= L ? l: (width >= M ? m : (width >= S ? s : t));
                /* give a sorting order for positions to display */
                col._pos = _pos < i
                    ?   (_pos - 0.5 + i * 0.001)
                    :  _pos > i
                        ?   (_pos + 0.5 - i * 0.001)
                        :   _pos;
            }
            let display_type = options._display_type = width >= L ? "l": (width >= M ? "m" : (width >= S ? "s" : "t"));
            options._class_names = options.class_names?.[display_type] || {};
            setCols(cols.sort((a, b) => a._pos - b._pos));
            /* if sum of all col._size <= 1000, single row */
            setIsTable(cols.reduce((acc, col) => acc + col._size, 0) <= 100);
        }, [_cols, width >= L, width >= M, width >= S, width === 0]
    );

    return (
        <div className={`${className || options._class_names?.container || ""}`}
            ref={container_el}
        >
            {
                is_table
                ?   <div className='w3-row w3-small w3-padding-sides-8 w3-light-grey w3-bold' >
                        {
                            cols.map((col, i) => {
                                if(!col._size) return null;
                                return <div key={getUKey(col)} className="w3-col w3-padding-8" style={{"width": col._size + "%" }}>
                                    {col.header || col.title || <>&nbsp;</>}
                                </div>
                            })
                        }
                    </div>
                :   null
            }
            {   callbacks?.onReorder
                ?   <ReorderableList onReorder={callbacks.onReorder} list_id={options.list_id}>
                    {
                        rows.map(
                            (row, i) => row && <EditableTableRow 
                                row={row} 
                                key={getUKey(row)} 
                                cols={cols} 
                                callbacks={callbacks}
                                actions={actions}
                                options={options}
                            />
                        )
                    }
                    </ReorderableList>
                :   rows.map(
                        (row, i) => row && <EditableTableRow 
                            row={row} 
                            key={getUKey(row)} 
                            cols={cols} 
                            callbacks={callbacks}
                            actions={actions}
                            options={options}
                        />
                    )                
            }
        </div>
    )
}

function renderWith(col, row){
    const {key, render} = col;
    if(render === null) return;
    if(render){
        const ret = render(row);
        if(ret !== undefined) return ret;
    }
    if(key === undefined) return null;
    return getKeyPath(row, key);
}

function editWith(col, row, updates){
    let ret = undefined;
    const {key, editor} = col;
    if(editor === null) return null; // explicit null means don't display anything
    if(editor) ret = editor(row, updates); // editor is a function

    if(ret === undefined){
        if(key){
            if(col.selection){ // key value <selection options>
                return <select className="w3-select" 
                    defaultValue={updates[key] || row[key] || (updates[key]= col.selection[0]?.[0])} // set the update key to first value if none set
                    onChange={(evt) => updates[key] = evt.target.value}
                >
                    {
                        col.selection.map(
                            (kv, i) => <option key={i} value={kv[0]}>{kv[1]}</option>
                        )
                    }
                </select>
            }
            else{
                ret = <input className="w3-input" type="text"
                    defaultValue={getKeyPath(row, key)}
                    onChange={
                        (evt) => updates[key.replace(".", "_")] = evt.target.value
                    }
                    {...(col.editor_props || {})}
                />
            }
        }
    }
    return ret;
}

var _counter = 0;

function EditableTableRow({row: _row, cols, callbacks, options}){
    _row.__etr_id = _row.__etr_id || (_row.__etr_id= ++_counter);
    var etr_broadcast_id = `etr_${_row.__etr_id}`
    /* create a broadcastable update id, that can change whenever there is a broadcast event */
    const [row] = useBroadcastedState(etr_broadcast_id, _row);
    const [sub_rows, setSubRows] = useState(null);
    const ctx = useRef({"updates": row.__updates || (row.__updates= {})}).current;

    row.rerender = (updated_row) => broadcaster.broadcast_event(etr_broadcast_id, updated_row || {...row});

    useEffect(() => {
        /* start adding to row, until 100% is reached and the continue to next row */
        let sub_row_width = 0;
        let sub_rows = [];
        let sub_row = [];
        let prev_section = undefined;
        for(let i = 0; i < cols.length; i++){
            const col = cols[i];
            /* if a new section starts automatically push them to next row */
            if(
                sub_row.length
                && (
                    (col.section && col.section !== prev_section) // new section starts
                    || (sub_row_width + col._size > 100)  // exceed 100% of this row
                )
            ){
                sub_rows.push(sub_row);
                sub_row = [];
                sub_row_width = 0;
            }
            sub_row.push(col);
            sub_row_width += col._size;
            prev_section = col.section;
        }
        if(sub_row.length) sub_rows.push(sub_row);
        setSubRows(sub_rows);
    }, [row, cols]);

    const onRowClick = useCallback(
        () => {
            !(row.__is_editing || row.__is_col_editing) && callbacks?.onRowClick?.(row)
        }, [row]
    );

    if(!sub_rows) return null;
    /* if it's a deleted row, render nothing */
    if(row.__is_deleted) return null;
    /* if it's a new row, and cancelled editing, render nothing */
    if(row.__is_new && !row.__is_editing) return null;
    let prev_section = null;
    return (
        <div className={`${options._class_names?.row || "w3-border-bottom"}`} onClick={onRowClick}>
            {
                sub_rows.map((cols, j) => {
                    /* 
                        THIS IS THE MAIN RENDERING OF SUB ROW COLUMNS
                        AFTER THIS BLOCK IS MUMBO JUMBO FOR SECTION DISPLAY
                    */
                    let sub_row_cols = cols.map((col, i) => {
                        let editing_el = undefined;
                        if(row.__is_editing || row.__is_col_editing === col){
                            editing_el = editWith(col, row, ctx.updates); // editing mode
                        }

                        let el = editing_el; // undefined if not editing
                        if(el === undefined) el = renderWith(col, row); // if editing return undefined-> render original
                        if(!el && sub_rows.length > 1) return null;
                        if(!col._size) return null;
                        /* function to edit individual coulmns */
                        const editCol = (col, evt) => {
                            if(row.__is_editing || row.__is_col_editing) return; // already editing other column, cancel previous first
                            evt.stopPropagation();
                            row.__is_col_editing = col;
                            row.rerender();
                        }

                        return (
                            <div key={getUKey(col)} className="w3-padding-8" style={{"width": col._size + "%"}}
                                onClick={
                                    (sub_rows.length <= 1 && col.is_col_editable)  // table view
                                    ? (evt) => editCol(col, evt) 
                                    : null
                                }
                            >
                                {
                                    /* mobile/subrows view, click on title to edit */
                                    sub_rows.length > 1
                                    ?   <div className='w3-text-grey w3-bold w3-small w3-margin-bottom-8'
                                            onClick={
                                                col.is_col_editable 
                                                ? (evt) => editCol(col, evt)
                                                : null
                                            }
                                        >
                                            {col.title}
                                        </div>
                                    :   null
                                }
                                {el}
                                {
                                    /* column level editing */
                                    (editing_el && row.__is_col_editing === col)
                                    ?   <EditingActionsColum row={row} callbacks={callbacks} updates={ctx.updates} />
                                    :   null
                                }
                            </div>
                        );
                    }).filter(col => col);

                    /* should show section ? */
                    let cur_section =  cols[0].section;
                    let section_info = (
                        sub_row_cols?.length && cur_section 
                            && prev_section != cur_section 
                            && options.sections?.[cur_section]
                    );

                    const ret = (
                        <React.Fragment key={j}>
                            {
                                section_info 
                                ?   <div className='tw-my-2 tw-p-2 tw-text-lg'>
                                        <div className='tw-font-bold'>{section_info.title}</div>
                                        {
                                            section_info.description 
                                            ?   <div className='tw-text-gray-600 tw-text-sm'>{section_info.description}</div>
                                            :   null
                                        }
                                    </div>
                                :   prev_section && !cur_section && j > 0 && <hr className="tw-my-8" />
                            }
                            <div className="w3-flex-row">
                                {sub_row_cols}
                            </div>
                        </React.Fragment>
                    );
                    (!cur_section || section_info) && (prev_section= cur_section);
                    return ret;
                })
            }                
        </div>
    );
}

function EditingActionsColum({row, callbacks, updates}){
    const [errors, setErrors] = useState(null);
    return (
        <div className='w3-flex-row-wrap w3-array-list-8 w3-small w3-pointer'>
            {
                errors
                ?   <GenericException ex={errors} />
                :   null
            }
            <span className="w3-round-xxlarge w3-text-green"
                onClick={
                    async (evt) => {
                        evt.stopPropagation();
                        if(Object.entries(updates).length === 0){ // no updates
                            Object.assign(row, {"__is_editing": false, "__is_col_editing": null}); // cancel editing
                            row.rerender();
                            return;
                        }
                        const [updated_row, errors] = await callbacks.onUpdate(row, updates);
                        setErrors(errors);
                        if(updated_row){
                            Object.assign(updated_row, {"__is_editing": false, "__is_col_editing": null}); // cancel editing
                            Object.assign(row, updated_row); // update previous data also, just in case, it's used somewhere
                            row.rerender(updated_row);
                        }
                    }
                }
            >
                Update
            </span>
            <span className="w3-round-xxlarge w3-text-red" 
                onClick={
                    (evt) => {
                        evt.stopPropagation();
                        row.rerender({...row, "__is_editing": false, "__is_col_editing": null});
                        callbacks?.onEditingCancel?.(row);
                    }
                }
            >
                Cancel
            </span>
        </div>
    );
}

function ActionsColumn({row, callbacks, actions}){
    const _actions = isObject(actions) ? Object.entries(actions) : (actions || []);
    return (
        <div className='w3-flex-row-wrap w3-flex-vcenter w3-list-horizontal-16 w3-small w3-pointer'>
            {
                callbacks?.onUpdate
                ?   <span className="w3-round-xxlarge w3-text-blue"
                        onClick={
                            (evt) => {
                                evt.stopPropagation();
                                row.rerender({...row, "__is_editing": true})
                            }
                        }
                    >Edit</span>
                :   null
            }
            {
                callbacks?.onDelete
                ?   <span className="w3-round-xxlarge w3-text-red"
                        onClick={
                            async (evt) => {
                                evt.stopPropagation();
                                if(!callbacks.onDelete || !window.confirm("Are you Sure?", )) return;
                                const [deleted, errors] = await callbacks.onDelete(row);
                                errors && GenericException.showPopup(errors);
                                if(deleted){
                                    row.__is_deleted = true;
                                    row.rerender();
                                }
                            }
                        }
                    >Delete</span>
                :   null
            }
            {
                _actions.map(([title, action]) => (
                    <span key={getUKey(action)} className={`w3-no-wrap w3-round-xxlarge w3-text-${CSS_COLOR(getUKey(action))}`}
                        onClick={
                            (evt) => {
                                evt.stopPropagation();
                                action(row, evt)?.then(updated_row => {
                                    updated_row !== undefined && row.rerender(updated_row);
                                });
                            }
                        }
                    >
                        {title}
                    </span>
                ))
            }
        </div>
    )
}

export {EditableTable, EditableTableRow}
